<template>
  <div class="braintree-input-wrap text-left">
    <label>
      {{ label }}
    </label>
    <v-skeleton-loader
      class="braintree__loader"
      v-show="showLoader"
      type="text"
    />
    <div v-show="!showLoader" :class="placeholderClass"></div>
  </div>
</template>

<script>
export default {
  name: 'BraintreeInput',
  props: ['field', 'label', 'showLoader'],
  computed: {
    placeholderClass() {
      return `braintree-input braintree-input--${this.field}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.braintree-input-wrap {
  margin-bottom: 12px;
}

.braintree-input {
  height: 32px;
  border-bottom: 1px solid $text-color-dark;

  &.braintree-hosted-fields-invalid {
    border-color: $color-danger;
  }

  &.braintree-hosted-fields-focused {
    border-color: $color-brand;
  }
}

.v-label--braintree {
  transform: translateY(-24px) scale(1);
  left: 0px;
  right: auto;
  position: absolute;
}

.braintree__loader {
  margin-top: 12px;
}
</style>
