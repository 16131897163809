import braintree from 'braintree-web'

import store from '@/store'
import api from '@/helpers/api'

export default {
  /**
   * Fetch braintree token from backend to init card form
   */
  async init(context, editedData = null) {
    // teardown already-initialized instances
    if (context.braintree?.instances) {
      context.braintree.instances.hostedFields.teardown()
      context.braintree.instances.threeDSecure.teardown()
    }

    let groupAccountId
    try {
      groupAccountId = context.groupAccountId

      if (!groupAccountId) {
        // pass the default group id to init braintree, in case the new group is not created
        groupAccountId = context.$store.state.companyData.account_id
      }
    } catch (error) {
      // show country not supported message
      context.$store.dispatch(
        'setSystemMessage',
        context.$t('g_unsupported_country')
      )
      return
    }

    context.isBraintreeLoaded = false

    try {
      const braintreeSettings = await this.getBraintreeSettings(groupAccountId)
      const isUsing3ds = braintreeSettings.three_ds_required

      context.braintree = {
        settings: {
          is3dsRequired: isUsing3ds,
          verificationAmount: parseFloat(braintreeSettings.verification_amount)
        }
      }

      const braintreeClientInstance = await braintree.client.create({
        authorization: braintreeSettings.token
      })
      const actualCard = editedData?.payment_cards.find(
        (card) =>
          card.account_payment_method_id ===
          editedData.default_account_payment_method_id
      )
      const paymentCardMask = actualCard
        ? actualCard.masked_pan
        : '**** **** **** **** ****'
      const prefilledData = {
        card_masked_pan: paymentCardMask,
        card_expiry_date: '**/****',
        cvv: '***'
      }

      const braintreeInstances = await this.setup(
        braintreeClientInstance,
        prefilledData
      )

      context.braintree.instances = {
        hostedFields: braintreeInstances[0],
        threeDSecure: braintreeInstances[1]
      }

      context.isBraintreeLoaded = true
    } catch (error) {
      // skip display the error if user close the popup before the braintree form is initialized
      if (error.code && error.code === 'HOSTED_FIELDS_INVALID_FIELD_SELECTOR') {
        return
      }
      context.$store.dispatch('setSystemMessage', error)
    }
  },

  setup(clientInstance, prefilledData) {
    const promises = [
      braintree.hostedFields.create({
        client: clientInstance,
        fields: {
          number: {
            selector: '.braintree-input.braintree-input--card-number',
            placeholder: prefilledData.card_masked_pan
          },
          cvv: {
            selector: '.braintree-input.braintree-input--cvv',
            placeholder: prefilledData.cvv
          },
          expirationDate: {
            selector: '.braintree-input.braintree-input--card-expiry',
            placeholder: prefilledData.card_expiry_date
          }
        },
        styles: {
          // Style all elements
          input: {
            'font-family': 'Roboto, Helvetica, Arial',
            'font-size': '20px',
            'font-weight': '500',
            color: '#333'
          },
          '.invalid': {
            color: '#eb5757'
          }
        }
      })
    ]

    promises.push(
      braintree.threeDSecure.create({
        client: clientInstance,
        version: 2
      })
    )

    return Promise.all(promises)
  },

  async submit(context, submitCallback, options) {
    if (!context.braintree) {
      context.errorMessage = context.$t('settings_billing_card_service_loading')
      context.$emit('error')
      return
    }

    try {
      const isRetrying = options?.retryWith3ds
      const braintreeTokenizedPayload =
        await context.braintree.instances.hostedFields.tokenize()

      // save tokenized information for automatic retry
      // hide waiting spinner before 3ds popup shows
      store.dispatch('setPageReadyStatus', false)

      let verificationNonce = braintreeTokenizedPayload.nonce
      // test mode: skip 3ds for the first time
      const isTestingMode = context.isTesting2099 && !isRetrying
      // force 3ds if retrying after error 2099
      // for normal case, run 3ds only as backend specified
      const isTriggeringThreeDs =
        !isTestingMode &&
        (isRetrying || context.braintree.settings.is3dsRequired)

      if (isTriggeringThreeDs) {
        const threeDsVerificationPayload =
          await context.braintree.instances.threeDSecure.verifyCard({
            onLookupComplete: (data, next) => {
              next()
            },
            amount: context.braintree.settings.verificationAmount,
            nonce: braintreeTokenizedPayload.nonce,
            bin: braintreeTokenizedPayload.details.bin
          })

        if (
          threeDsVerificationPayload.liabilityShiftPossible &&
          !threeDsVerificationPayload.liabilityShifted
        ) {
          // handle when braintree popup closed before verification complete
          throw new Error(context.$t('settings_billing_3d_secure_failed'))
        }

        verificationNonce = threeDsVerificationPayload.nonce
      }

      const params = { braintree_nonce: verificationNonce }
      submitCallback(params)
    } catch (err) {
      context.errorMessage = err
      context.$emit('error')
    }
  },

  async getBraintreeSettings(groupAccountId) {
    const apiBraintreeSettings = {
      method: 'get',
      service: 'billing',
      url: 'token/braintree',
      params: { account_id: groupAccountId }
    }

    return await api.promise(apiBraintreeSettings)
  }
}
